import { Title } from "@mantine/core";
import React from "react";

export const SignUpPage = () => {
  return (
    <div>
      <Title order={4}>Sign in</Title>
    </div>
  );
};
